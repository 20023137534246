const REGION = 'me-south-1';

export default {
  environment: 'dev',
  production: false,
  TAX_PERCENTAGE: 15,
  GOOGLE_KEY: 'AIzaSyDyKLC3b_LgJKU-vbBwm1sWTm80ir50074',
  MAIN_DOMAIN: 'grow.dev.naeeman.com',
  PORTAL_URL: 'https://grow.dev.naeeman.com',
  JWT_SECRET_KEY: 'cc7e0d44fd473002f1c42167459001140ec6389b7353f8088f4d9a95f2f596f2',
  s3: {
    REGION,
    BUCKET: 'naeeman-dev-media-bucket',
    BUCKET_URL: 'https://naeeman-dev-media-bucket.s3.me-south-1.amazonaws.com/public/',
  },
  API: {
    REGION,
    URL: 'https://api.dev.naeeman.com/v1',
    // URL: 'http://localhost:3333/v1',
  },
  NOTIFICATION_API: {
    REGION,
    URL: 'https://api.dev.naeeman.com/n1',
  },
  cognito: {
    REGION,
    USER_POOL_ID: 'me-south-1_MIbc3hji8',
    APP_CLIENT_ID: 'm07e38i31h0ok0t7odkapblv7',
    IDENTITY_POOL_ID: 'me-south-1:23e5ab9f-d11f-4f5c-86e0-0dd490d4339e',
  },
  APPSYNC: {
    REGION,
    GRAPHQL_URL: 'https://api.dev.naeeman.com/graphql',
    AUTH_TYPE: 'API_KEY',
    API_KEY: 'da2-7tbli7phmjhnlirhoh7f2qhbiq',
  },
};
